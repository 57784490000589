import { Dropdown, TextField } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { ParagraphLines } from "../../../html-constants/styles/html-styles";
import { ProductType } from "../../../lib/enums/common-enums";
import { IExtensionMetadataSubmitProps } from "../../../lib/interfaces/common-interface";
import {
  BigIdValidation,
  BigIntValidation,
  CRXIdValidation,
  GenericStringValidation,
  SubmissionIdValidation,
} from "../../../lib/utils/util";
import useDropdown from "../../hooks/useDropdown";
import useInput from "../../hooks/useInputValidation";
import SectionTitleHelperText from "../../view/section-title-helper-text/section-title-helper-text";
import { ProductTypeDropdownOptions } from "../dropdown-options";
import "./extension-metadata-submit.scss";

export default function ExtensionMetadataSubmit(props: IExtensionMetadataSubmitProps): React.ReactElement {
  const productType = useDropdown(
    props.initialData.productType as ProductType,
    ProductTypeDropdownOptions,
    true,
    localeStrings[`ProductTypeErrorText`],
  );
  const extensionVersion = useInput(
    props.initialData.extensionVersion,
    true,
    localeStrings[`ExtensionVersionErrorText`],
  );
  const bigId = useInput(props.initialData.bigId, true, localeStrings[`BigIdErrorText`], BigIdValidation);
  const crxId = useInput(props.initialData.crxId, true, localeStrings[`CRXIdErrorText`], CRXIdValidation);
  const extensionName = useInput(
    props.initialData.extensionName,
    true,
    localeStrings[`GenericInputErrorText`],
    GenericStringValidation,
  );
  const description = useInput(
    props.initialData.description,
    true,
    localeStrings[`GenericInputErrorText`],
    GenericStringValidation,
  );
  const installCount = useInput(
    props.initialData.installCount,
    true,
    localeStrings[`InstallCountErrorText`],
    BigIntValidation,
  );
  const submissionId = useInput(
    props.initialData.submissionId,
    true,
    localeStrings[`SubmissionIdErrorText`],
    SubmissionIdValidation,
  );
  React.useEffect(() => {
    if (
      bigId.isValidInput &&
      crxId.isValidInput &&
      description.isValidInput &&
      extensionName.isValidInput &&
      extensionVersion.isValidInput &&
      installCount.isValidInput &&
      productType.isValidInput &&
      submissionId.isValidInput
    ) {
      props.setData({
        bigId: bigId.value,
        crxId: crxId.value,
        description: description.value,
        extensionName: extensionName.value,
        extensionVersion: extensionVersion.value,
        installCount: parseInt(installCount.value),
        productType: productType.selectedItem as ProductType,
        submissionId: submissionId.value,
      });
    } else {
      props.setData(null);
    }
  }, [
    bigId.value,
    crxId.value,
    description.value,
    extensionName.value,
    extensionVersion.value,
    installCount.value,
    productType.selectedKey,
    submissionId.value,
  ]);

  return (
    <div className={"extension-metadata"}>
      <div className="crxid-search-box">
        <TextField
          label={localeStrings[`CRXID`]}
          {...crxId}
          disabled={!!props.initialData.crxId}
        />
      </div>
      <SectionTitleHelperText
        title={localeStrings[`ExtensionMetadata_SectionTitle`]}
        helperText={localeStrings[`ExtensionMetadata_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <TextField
          label={localeStrings[`ExtensionMetadata_BigId`]}
          disabled={!props.allowEditOnNonEditableField || !!props.initialData.bigId}
          {...bigId}
        />
        <TextField
          label={localeStrings[`ExtensionMetadata_Name`]}
          disabled={!props.allowEditOnNonEditableField}
          {...extensionName}
        />
        <TextField
          label={localeStrings[`ExtensionMetadata_ExtensionVersion`]}
          {...extensionVersion} />
        <TextField
          label={localeStrings[`ExtensionMetadata_InstallCount`]}
          disabled={!props.allowEditOnNonEditableField}
          {...installCount}
        />
        <TextField
          label={localeStrings[`ExtensionMetadata_SubmissionID`]}
          disabled={!props.allowEditOnNonEditableField}
          required {...submissionId} />
        <Dropdown
          label={localeStrings[`ExtensionMetadata_ProductType`]}
          {...productType}
          placeholder={localeStrings[`SelectAnOption`]}
        />
        <TextField
          className="description-section"
          label={localeStrings[`ExtensionMetadata_Description`]}
          multiline
          rows={ParagraphLines}
          disabled={!props.allowEditOnNonEditableField}
          {...description}
        />
      </div>
    </div>
  );
}
