import * as _ from 'lodash';
import localeStrings from '../../../assets/localeStrings/english.json';
import { AttackVectorCategory, Keys, ProductType } from '../enums/common-enums';
import { AxiosError } from 'axios';
import { guidRegex, versionRegex } from '../constants/constants';
import { IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { StatusCodes } from 'http-status-codes';
import {
  IAuditDetails,
  IDeveloperDetails,
  IExtensionAnalysis,
  IExtensionMetaData,
  ISubmitUserRecord,
  IThreatAnalysisDatabaseRecord,
  IThreatDetails,
} from "../interfaces/common-interface";

/**
 *
 * @param accessToken string containing token for auth. Empty string is valid.
 * @returns Axios authorization headers with Bearer token, can be empty object if string is empty.
 */
export function GetBearerHeader(accessToken: string) {
  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
}

export const findMatchingDropDownOption = (str: string, dropDownOptions: IDropdownOption<string>[]): any => {
  return dropDownOptions.find((ele) => ele.text === str).key;
};

export const ExtractExtensionMetaDataFromRecord = (
  record: IThreatAnalysisDatabaseRecord,
  id?: string,
): IExtensionMetaData => {
  const crxId = CRXIdValidation(id) ? id : "";
  const bigId = BigIdValidation(id) ? id : "";
  return {
    bigId: record?.bigId || bigId,
    crxId: record?.crxId || crxId,
    description: record?.description || "",
    extensionName: record?.extensionName || "",
    extensionVersion: record?.extensionVersion || "",
    installCount: record?.installCount || 0,
    productType: record?.productType || null,
    submissionId: record?.submissionId || "",
  };
};

export const ExtractThreatDetailsDataFromRecord = (record: IThreatAnalysisDatabaseRecord): IThreatDetails => {
  return {
    publishType: record?.publishType || null,
    sourceOfReport: record?.sourceOfReport || null,
    symptomsOrBehavior: record?.symptomsOrBehavior || "",
  };
};

export const ExtractDeveloperDetailsDataFromRecord = (record: IThreatAnalysisDatabaseRecord): IDeveloperDetails => {
  return {
    developerAccountType: record?.developerAccountType || null,
    developerName: record?.developerName || "",
    developerWebsite: record?.developerWebsite || "",
    privacyPolicyURL: record?.privacyPolicyURL || "",
    publisherId: record?.publisherId || "",
  };
};

export const ExtractExtensionAnalysisDataFromRecord = (record: IThreatAnalysisDatabaseRecord): IExtensionAnalysis => {
  return {
    attackVectorCategories: record?.attackVectorCategories || null,
    recommendationReason: record?.recommendationReason || "",
    comments: record?.comments || "",
    isMalicious: record?.isMalicious || null,
    ttp: record?.ttp || "",
    iocType: record?.iocType || null,
    iocValue: record?.iocValue || "",
    isReproducible: record?.isReproducible || null,
    mitreAttack: record?.mitreAttack || null,
    policyFailure: record?.policyFailure || "",
    recommendation: record?.recommendation || null,
    vulnerability: record?.vulnerability || "",
    isActiveInChromeStore: record?.isActiveInChromeStore || false,
    chromeStoreUrl: record?.chromeStoreUrl || "",
    isDaasPerformed: record?.isDaasPerformed || false,
    daasOutputLogFile: record?.daasOutputLogFile || "",
    detectedAttackVectors: record?.detectedAttackVectors || [],
    detectedRules: record?.detectedRules || [],
    reportedRules: record?.reportedRules || []
  };
};

export const ExtractAuditDetailsFromRecord = (record: IThreatAnalysisDatabaseRecord): IAuditDetails => {
  return {
    createdBy: record?.createdBy || "",
    createdTime: record?.createdTime || "",
    lastUpdatedBy: record?.lastUpdatedBy || "",
    lastUpdatedTime: record?.lastUpdatedTime || "",
  };
};

export const AreUpdatedRecordsSame = (
  report: Omit<ISubmitUserRecord, "reportState">,
  currentDbReport: IThreatAnalysisDatabaseRecord,
): boolean => {
  const recordWithoutSomeProperties = { ...currentDbReport };
  delete recordWithoutSomeProperties.createdBy;
  delete recordWithoutSomeProperties.createdTime;
  delete recordWithoutSomeProperties.lastUpdatedBy;
  delete recordWithoutSomeProperties.lastUpdatedTime;
  delete recordWithoutSomeProperties.reportState;
  delete recordWithoutSomeProperties.id;
  delete recordWithoutSomeProperties.reviewName;
  return _.isEqual(report, recordWithoutSomeProperties);
};

export const FormRecord = (
  a: IExtensionMetaData,
  b: IDeveloperDetails,
  c: IThreatDetails,
  d: IExtensionAnalysis,
): Omit<ISubmitUserRecord, "reportState"> => {
  return {
    ...a,
    ...b,
    ...c,
    ...d,
    isMalicious: ((d?.isMalicious as unknown) as string) === localeStrings[`Yes`],
    isReproducible: ((d?.isReproducible as unknown) as string) === localeStrings[`Yes`],
  };
};

export const CRXIdValidation = (value: string): boolean =>
  typeof value === "string" && RegExp("^[a-pA-P]{32}$").test(value);

export const GuidValidation = (value: string): boolean =>
  typeof value === "string" &&
  new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$", "i").test(value);

export const SubmissionIdValidation = (value: string): boolean => typeof value === "string" && RegExp("^[0-9]{19}$").test(value);

export const BigIdValidation = (value: string): boolean =>
  typeof value === "string" && new RegExp("^[a-zA-Z0-9]{12}$", "i").test(value);

export const GenericStringValidation = (value) => typeof value === "string" && !!value;
export const BigIntValidation = (value) => /^\d+$/.test(value);
export const ShouldEditOnNonEditableField = (noMetaData: string, error: AxiosError<any>): boolean =>
  !!noMetaData && error?.response?.status === StatusCodes.NOT_FOUND;

export const IsValidChromeStoreUrl = (value: string): boolean => {
  if (!value) {
    return true;
  }
  try {
    return new URL(value).host === "chrome.google.com" ? true : false;
  } catch {
    return false;
  }
};

export const IsValidUrl = (value: string): boolean => {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
};

/**
 * Generates the options for combo box.
 * @param max Number of options to be generated. It is 1 index based.
 * @returns an array of generated options.
 */
export const ComboBoxOptionGenerator = (max: number): IComboBoxOption[] => {
  var resp = [];
  for (let i = 1; i <= max; ++i) {
    resp = resp.concat({ key: `pageNumberKey-${i.toString()}`, text: i.toString() },)
  }
  return resp;
}

/**
 * Generates the options for drop down.
 * @param Possible options.
 * @returns an array of generated options.
 */
export const DropdownOptionsGenerator = (options: string[]): IDropdownOption[] => {
  var result: IDropdownOption[] = [];
  if (!options?.length) {
    return result;
  }
  for (var i = 0; i < options.length; ++i) {
    result = result.concat({ key: options[i], text: options[i] });
  }
  result = result.concat({ key: Keys.NoneRuleId, text: AttackVectorCategory.None});
  return result;
}

export const isValidGuid = (input: string): boolean => {
    return guidRegex.test(input);
};

export const isValidVersion = (version: string): boolean => {
    return versionRegex.test(version);
};

export const getProduct = (path: string) : ProductType => {
  const pathArray = path.split("/");
  if (pathArray.length >=1 && pathArray[1] in ProductType) {
    return pathArray[1] as ProductType;
  }
}